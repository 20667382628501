import React from 'react'
import { Button } from 'antd';    
import { useNavigate } from 'react-router-dom'
import {ClockCircleOutlined, HomeOutlined, MailOutlined, PhoneOutlined} from '@ant-design/icons';


import classes from '../styles.module.css'

const Footer = () => {
    const navigate = useNavigate()

    const handleNavigation = (route) => {
       navigate(`/${route}`)
    }

    return (
        <div className={classes.footerContainer}>
            <div className={classes.footerNavigationContainer}>
                <Button color="primary" variant="link" onClick={() => handleNavigation('refund-policy')}>
                    Refund Policy
                </Button>
                <Button color="primary" variant="link" onClick={() => handleNavigation('privacy-policy')}>
                    Privacy Policy
                </Button>
                <Button color="primary" variant="link" onClick={() => handleNavigation('user-consent')}>
                    User Consent
                </Button>
                <Button color="primary" variant="link" onClick={() => handleNavigation('legal-disclaimer')}>
                    Legal Disclaimer
                </Button>
                <Button color="primary" variant="link" onClick={() => handleNavigation('advertising-disclaimer')}>
                    Advertising Disclaimer
                </Button>
                <Button color="primary" variant="link" onClick={() => handleNavigation('standard-disclaimer')}>
                    Standard Disclaimer
                </Button>
                <Button color="primary" variant="link" onClick={() => handleNavigation('grievance-redressal-process')}>
                    Grievance Redressal Process
                </Button>
                <Button color="primary" variant="link" onClick={() => handleNavigation('registered-research-analyst')}>
                    Registered Research Analyst
                </Button>
            </div>
            <div className={classes.footerSectionContainer}>
                <div className={classes.footerSectionHeader}>
                    <HomeOutlined style={{fontSize: '14px'}} /> 
                    <span>Address</span>
                </div>
                <div className={classes.footerSectionBody}>
                    TTC Industrial Area, koper kharine , Navi Mumbai, Maharashtra-400705.
                </div>
            </div>
            <div className={classes.footerSectionContainer}>
                <div className={classes.footerSectionHeader}>
                    <PhoneOutlined style={{fontSize: '14px'}} /> 
                    <span>Customer Support</span>
                </div>
                <div className={classes.footerSectionBody}>
                    <a href="tel:0123456789">0123456789</a>
                </div>
            </div>
            <div className={classes.footerSectionContainer}>
                <div className={classes.footerSectionHeader}>
                    <ClockCircleOutlined style={{fontSize: '14px'}} /> 
                    <span>Office Hours</span>
                </div>
                <div className={classes.footerSectionBody}>
                    Mon - Sat 9:00 AM to 6:00 PM <br />
                    Sun - closed 
                </div>
            </div>
            <div className={classes.footerSectionContainer}>
                <div className={classes.footerSectionHeader}>
                    <MailOutlined style={{fontSize: '14px'}} /> 
                    <span>Mail</span>
                </div>
                <div className={classes.footerSectionBody}>
                    <a href="mailto:compliance@bullinvestor.com">compliance@bullinvestor.com</a>
                    <br />
                    <a href="mailto:sales@bullinvestor.com">sales@bullinvestor.com</a>
                </div>
            </div>
        </div>
    )
}

export default Footer