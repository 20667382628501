import { useRef, useState } from 'react';
import { notification, Spin } from 'antd';
import emailjs from 'emailjs-com';
import {
    addDoc,
    collection
} from "firebase/firestore";
import { db } from "../firebase.config.js";

import DigitalSignature from '../DigitalSignature';
import FormContainer from '../FormContainer';
import classes from '../styles.module.css';
import Constants from './InputFields.json';


const UserConsent = () => {
    const initialData = {
        to_name: '',
        to_email: '',
        mobile: '',
        agentName: ''
    }

    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({})
    const formRef = useRef()
    const [api, contextHolder] = notification.useNotification();
    const [showDigitalSignatureModal, setShowDigitalSignatureModal] = useState(false)



    const handleUserConsentSubmit = (data) => {
        setShowDigitalSignatureModal(true)
        setFormData(data)
    }

    //firebase
    const usersCollectionRef = collection(db, "users");

    const createUser = async(sign) => {
        try{
            const newUser = await addDoc(usersCollectionRef, { fullName: formData.to_name, email: formData.to_email, mobile: formData.mobile, agentName: formData.agentName, digiSign: sign, emailVerified: false });
            return newUser.id
        } catch(error) {
            console.log("error in creating user")
            api.error({
                message: 'Error',
                description:
                    'Error in creating new user.',
            });
            throw error
        }
    }

    const handleSignatureClose = () => {
        setShowDigitalSignatureModal(false)
    }

    const handleSignatureSubmit = async(signature) => {
        try {
            setIsLoading(true)
            setShowDigitalSignatureModal(false);
            const userId = await createUser(signature);
            await emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, {...formData, ...{userId: userId}}, process.env.REACT_APP_PUBLIC_KEY)
            console.log('Mail Sent Successfully')
            setFormData(initialData)
            formRef.current.resetFormData()
            api.success({
                message: 'Success',
                description:
                'Verification Mail Sent Successfully. please check your mail.',
            });
        } catch(error) {
             api.error({
                message: 'Something went wrong!'
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
            <>
            <Spin tip="Loading" size="large" fullscreen spinning={isLoading} />   
            {contextHolder}
            {showDigitalSignatureModal ? 
                <DigitalSignature handleClose={handleSignatureClose} handleSubmit={handleSignatureSubmit} />
            : null}
            <div className={classes.footerLinksContainer}>
                <p>
                    By visiting our website (BullInvestor.co.in) or utilizing  and processing a payment for any subscription to our research services, you confirm your acceptance of all terms and conditions, legal disclaimers, disclosures, policies, and user consent guidelines set forth by Bull Investor on our official site.However, it is important to note that the information provided is for educational purposes only and should not be construed as financial advice or a guarantee of returns.
                </p>
                <p>
                We reserve the right to modify these terms and conditions, along with all related legal disclaimers, disclosures, policies, and user consent guidelines, at any time. Your ongoing use of our website and as well as your subscription to any of our research services, signifies your acceptance of any revised or updated terms and conditions. We encourage you to regularly check the links for the Terms and Conditions, Legal Disclaimer, Disclosure, User Consent, Refund Policy, and Privacy Policy on our website to remain informed about any changes we may implement.
                </p>
                <p>
                    In this document, the term Bull Investor (Bullinvestor.co.in) refers to our website and its associated services, as well as its owners, operators, and all employees and affiliates connected with the firm. The term "You" designates any individual who visits, uses, or subscribes to the services offered by Bull Investor through the website.
                </p>

                <p>
                    1. By using our website and services, you acknowledge that you have reviewed and comprehended these Terms and Conditions. If you have any reservations or do not fully agree with any aspect of these terms, we kindly ask that you refrain from using our services. Your understanding and cooperation are greatly appreciated.
                </p>
                <p>
                    2. Bull Investor shall not be liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services, even if we have been advised of the possibility of such damages.
                </p>
                <p>
                    3. We reserve the right to modify these Terms and Conditions/ user consent at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our services after such changes constitutes your acceptance of the new terms.
                </p>

                <p>
                    4. To access certain services, you may need to subscribe and make a payment. You agree to provide accurate billing information.
                </p>

                <p>
                    5. you acknowledge and agree that Bull Investor, along with its owners, managers, employees, and associates, does not guarantee or assure the accuracy or consistency of any research alerts or services provided. Any accuracy level mentioned—whether communicated in writing, verbally, or through advertisements, websites, —is for indicative purposes only. You understand and accept that the accuracy level or percentage of any research services or alerts from Bull Investor may vary over time. Bull Investor and its team make no commitment to deliver research services or alerts with a fixed accuracy level or percentage.
                </p>

                <p>
                    6. By subscribing to our services, you affirm that all information you provided during registration is accurate and complete to the best of your knowledge. You agree to promptly notify us of any updates or changes to this information. You acknowledge that if any details are found to be false or misleading, you may be held responsible for any consequences arising from the misrepresentation.
                </p>

                <p>
                    7. You acknowledge and agree that the services provided by Bull Investor consist of general technical analysis and trading research and are not intended as specific investment advice. Any decision to enter or avoid a trade is solely yours, as is the choice regarding the quantity of stocks or F&O lots to trade based on our research reports or alerts. You accept full responsibility for any profits or losses resulting from your trading activities.
                </p>

                <p>
                    8. You accept full responsibility for all trades conducted in your Demat or trading account throughout your subscription with Bull Investor. Any results, whether profits or losses, arising from trades based on our research are entirely your responsibility. You further acknowledge that Bull Investor offers research services strictly for reference and not as specific investment advice. Any decisions to trade or invest, along with any resulting outcomes, are solely your own responsibility.
                </p>

                <p>
                    9. Any accuracy rate mentioned, whether in written form, verbally, or in our marketing materials, website is provided purely for indicative purposes.You understand that the accuracy levels of our research services or alerts may vary over time, and Bull Investor makes no commitment to uphold a specific accuracy rate.All decisions to trade or invest, as well as any resulting gains or losses, are solely your own responsibility.
                </p>

                <p>
                    10. You confirm that you fully understand and accept all terms, conditions, policies, disclosures, and disclaimers of Bull Investor. You are fully aware of the risks associated with stock market trading and investing, and despite these risks, you have chosen to subscribe to the research services provided by Bull Investor. You acknowledge that you have been informed of all aspects of these services, including the potential for loss in trading and investing. By subscribing to Bull Investor’s research services, you confirm that you are not expecting any fixed or guaranteed returns and that you are aware you may lose part or all of your investment capital by following the provided research reports, alerts, or recommendations.
                </p>

                <p>
                    11. By using our services, you acknowledge that you have reviewed and accepted all terms and conditions, legal disclaimers, refund policies, privacy policies, and user consent outlined on the official website of Bull Investor at Bullinvestor.co.in. Furthermore, you confirm that you have thoroughly read, understood, and agreed to the risks associated with our services as detailed in the disclaimer available on both our website and web platform.
                </p>

                <p>
                    12. By subscribing to the Bull Investor trading research alerts, you acknowledge that you have been adequately informed about the inherent risks associated with stock market trading and investing. Despite this awareness, you choose to proceed with the subscription willingly.
                </p>

                <p>
                    13. You confirm that any trading or investment activities will be conducted solely with your personal funds. Bull Investor, along with its owners, managers, and employees, shall not be held responsible for any trading or investing actions that involve borrowed capital or funds that are not your own.
                </p>

                <p>
                    14. You acknowledge and agree that the research analyst firm, Bull Investor, along with its owner, employees, and associates, does not offer any services related to order execution for trading or investing purposes.
                </p>

                <p>
                    15. You recognize that the fees paid to Bull Investor are strictly for the provision of research alerts and not an investment in the stock market itself. You further understand that these fees are non-refundable under any circumstances. It has been clearly communicated to you by the representatives of Bull Investor that they will not invest any funds on your behalf in the stock market. By engaging with Bull Investor, you accept the inherent risks of trading and investing, and you understand that any losses incurred from your trading activities, whether or not based on Bull Investor’s research or guidance, are your sole responsibility. As such, you agree not to initiate any legal action or file complaints against Bull Investor, its owner, employees, or associates.
                </p>

                <p>
                    16. You confirm that you have thoroughly read and comprehended all the information in this document. You accept and agree to all the terms and conditions, including disclaimers, disclosures, and policies outlined on the official Bull Investor website (Bullinvestor.co.in). Your digital consent via this document serves as formal acknowledgment that you understand and accept the risks associated with trading and investing in the stock market.
                </p>
                <p>
                    17. Clients are required to communicate, respond to, and acknowledge correspondence exclusively through email addresses from the domain compliance@bullinvestor.com or any other address associated with the Bull Investor domain. The Research Analyst will not be held responsible for any emails sent or received by the client from any other email domain or address.
                </p>

                <p>
                    18. Investing in stocks and derivative instruments involves inherent market risks, and we cannot guarantee any specific returns or assurances on your investments. It's important to understand that market fluctuations may impact the performance of your investments.
                </p>

                <p>
                    19. By proceeding, you acknowledge that you have reviewed all the available packages on our official website, Bullinvestor.co.in, including Equity Cash, Equity Cash Premium, Index Option (Standard Package), Index Option (premium Package), and Index Option (Combo Package).
                </p>

                <p>
                    20. Our historical performance is not a guarantee of future results, and we encourage investors to keep this in mind.
                </p>

                <p>
                    21. To prevent fraud, identity theft, money laundering, and client duplication, we may obtain your essential KYC documents from an authorized KYC Registration Agency for official use and record-keeping. By providing your consent, you acknowledge and agree to this process with Bull Investor.
                </p>

                <p>
                    22. Investors are advised that our research alerts and trade recommendations may not always yield profits, as market dynamics can shift unpredictably, diverging from projected trends.
                </p>

                <p>
                    23. Bull Investor’s Research Analyst does not assume responsibility for any profits or losses resulting from the execution of research alerts or trades shared.
                </p>

                <p>
                    24. Both parties agree to pursue online conciliation and/or online arbitration via the ODR Portal, adhering to the dispute resolution methods detailed in SEBI circular no. SEBI/HO/OIAE/OIAE_IAD-1/P/CIR/2023/131, issued on July 31, 2023, concerning "Online Dispute Resolution in the Indian Securities Market."
                </p>

                <p>
                    25. We strongly advise against responding to any calls from personal or unknown numbers claiming to represent Bull Investor and Research Analyst, especially if they request personal information, Demat or trading account login credentials, or fund transfers to personal accounts. Bull Investor and Research Analyst will not be responsible for any financial or non-financial transactions or actions taken by clients based on such communications. If you receive such requests, please refuse to provide any information and contact our official customer support number at (customer service number)for assistance and either can contact us through the official email id of the Bull investor 
                </p>

                <p>
                    26. All target and stop-loss levels are explicitly outlined in our research alerts and trades provided to clients. It is the client's responsibility to assess their own willingness to accept the risks associated with each trade. Based on this assessment, clients should determine the quantity and amount they wish to invest in each trade at their own discretion.
                </p>

                <p>
                    27. Bull Investor will deliver research recommendations to paid clients through our WhatsApp broadcast,  for the onboarding process is to be completed.The Research Analyst will not be held responsible if a client follows recommendations received through any other channels.
                </p>

                <p>
                    28. By engaging with Bull Investor’s research recommendations and alerts, you acknowledge that you are fully aware of the inherent risks associated with stock market trading and investing. You have chosen to utilize these services for your own benefit, understanding that any financial losses incurred through your trading activities are your sole responsibility. Furthermore, you agree that Bull Investor, including its owner, employees, and associates, cannot be held liable for any monetary losses resulting from your investment decisions.
                </p>

                <p>
                    29. You acknowledge that any fees paid to Bull Investor are exclusively for the provision of research alerts and related services. These payments do not constitute an investment in the stock market by Bull Investor or its representatives. Furthermore, you understand that these fees are non-refundable under any circumstances. By utilizing Bull Investor’s research reports and guidance, you accept the inherent risks associated with trading and investing. Any losses incurred from your trading activities, whether linked to Bull Investor's insights or not, are solely your responsibility. As such, you agree not to pursue any legal action or file any complaints against Bull Investor, its owner, employees, or associates.
                </p>


                <p>
                    30. For any queries or concerns, clients can reach us via email at compliance@bullinvestor.com. Alternatively, clients may contact our customer support at (customer care number) or reach out to our compliance team at (compliance phone number)
                </p>

                <p>
                    31. Clients are encouraged to visit our website and review all Terms and Conditions, Disclosures, Disclaimers, Refund Policies, User Consent, and other relevant policies before signing this User Consent document.
                </p>

                <p>
                    32. You acknowledge that you have thoroughly read and understood all the content in this document, including the terms and conditions, disclaimers, disclosures, refund policy, and other policies available on the official website of Bull Investor (Bullinvestor.co.in). By providing digital confirmation/consent through this document, you officially accept all the terms and conditions set forth by Bull Investor. This confirmation/consent serves as your final notification and acknowledgment.
                </p>

                <p>
                    33. By digitally confirming your consent through this document, you acknowledge and accept all terms and conditions established by Bull Investor. This confirmation serves as your official notice and signifies your understanding of the inherent risks associated with trading and investing in the stock market. You agree to proceed with full awareness of these risks.
                </p>

                <p>
                    By selecting the "I accept terms and conditions" option below, you confirm that you have read and accept all the terms and conditions specified in this document. Bull Investor will treat your acceptance as final and binding for all services and products you subscribe to. Your acknowledgment, whether explicitly confirmed or not, will be considered accurate and will be recorded in our active records.
                </p>
            </div>
            <div className={classes.userConsentFieldContainer}>
                <FormContainer 
                    title="User Consent"
                    inputFields={Constants.InputFields}
                    initialState={initialData}
                    submitBtnLabel="Proceed"
                    handleSubmit={handleUserConsentSubmit}
                    ref={formRef}
                />
            </div>
        </>
    )
}

export default UserConsent