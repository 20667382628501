
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Checkbox, Input, Modal, notification } from 'antd';

import { emailRegex, mobileRegex } from '../constants';
import classes from '../styles.module.css';
import TermsAndConditions from '../TermsAndConditions';

const FormContainer = forwardRef(({inputFields, initialState, title, submitBtnLabel, handleSubmit}, formRef) => {
    const [data, setData] = useState(initialState)
    const [termsChecked, setTermsChecked] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)

    const handleResetData = () => {
        setData(initialState)
        setTermsChecked(false)
    }

    useImperativeHandle(formRef, () => ({
        resetFormData: handleResetData
    }))


    const handleInputChange = (e) => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        })
    }

    const handleCheckboxChange = (e) => {
        setTermsChecked(e.target.checked)
    }

    const validateInputFields = () => {
        let isValid = true
        let errorMsg = ''
        Object.keys(data).some((key) => {
            if(data[key] === '') {
                isValid = false
                errorMsg = 'Please fill all the mandatory fields.'
            } else if(key === 'email' && !emailRegex.test(data[key])) {
                isValid = false
                errorMsg = 'Email format is incorrect.'
            } else if(key === 'mobile' && !mobileRegex.test(data[key])) {
                isValid = false
                errorMsg = 'Mobile Number format is incorrect. Supported formats are like +91<10-digits> or <10-digits>'
            }
        })
        return {
            isValid,
            errorMsg
        }
    }

    const handleRegister = () => {
        if(!termsChecked){
            return api.error({
                message: 'Error',
                description:
                    'Please review and accept the Terms and Conditions to proceed.',
            });
        }
        const {isValid, errorMsg} = validateInputFields()
        if(!isValid) {
            return api.error({
                message: 'Error',
                description: errorMsg,
            });
        }
        handleSubmit(data)
    }

    const handleTermsAndConditions = () => {
        setShowTermsAndConditions(true)
    }

    const handleModalClose = () => {
        if(showDisclaimerModal){
            setShowDisclaimerModal(false)
        } if(showTermsAndConditions) {
            setShowTermsAndConditions(false)
        }
    }

    return (
        <>
            {contextHolder}
             {showTermsAndConditions || showDisclaimerModal ? 
                (<Modal 
                    title="TERMS AND CONDITIONS" 
                    open 
                    footer={
                    <Button type="primary" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    }
                    onCancel={handleModalClose}>
                        <TermsAndConditions />
                </Modal>)
            : null}
            <div className={classes.cardContainer}>
                <h3 className={classes.cardHeader}>{title}</h3>
                <div className={classes.inputFieldsContainer}>
                    {inputFields && inputFields.map((field) => 
                        <Input id={field.name} placeholder={field.placeholder} onChange={handleInputChange} value={data[field.name]} />
                    )}
                </div>
                <Checkbox className={classes.termsAndConditions} onChange={handleCheckboxChange} checked={termsChecked}>
                    I accept 
                    <Button color="primary" variant="link" size="small" onClick={handleTermsAndConditions}>
                        terms and conditions
                    </Button>
                </Checkbox>
                <div className={classes.noteDisclaimer}>Note: Expert Research alerts will be provided only after successful acceptance of User Consent.</div>
                <Button type="primary" className={classes.registerBtn} onClick={handleRegister}>{submitBtnLabel || 'Register'}</Button>
            </div>
        </>
    )
})

export default FormContainer