// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sigCanvas {
    border: 1px solid black;
    width: 100%;
    height: 100%;
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/DigitalSignature/sigCanvas.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B","sourcesContent":[".sigCanvas {\n    border: 1px solid black;\n    width: 100%;\n    height: 100%;\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
