import classes from '../styles.module.css'

const StandardDisclaimer = () => {
    return (
        <div className={classes.footerLinksContainer}>
            <p>
               Bull Investor does not receive any form of compensation, remuneration, or other consideration from any party, affiliate, or subsidiary for distributing or facilitating the products or securities covered in its research services. Bull Investor does not engage in executing orders on behalf of users or subscribers. If someone claims to offer order execution services in the name of Bull Investor, users should decline such offers and notify us immediately. Should a user independently choose to proceed with such services, Bull Investor, along with its founders, team members, representatives, and stakeholders, will bear no responsibility for any results, including potential gains or losses, that may arise. Users agree to protect Bull Investor, its founders, staff, representatives, and stakeholders from any liabilities or damages that may result from any self-initiated execution services. By using Bull Investor’s services or those of its affiliates, users confirm they have read and accepted our terms and conditions.
            </p>
            <p>
                Bull Investor does not endorse or suggest any specific stock or commodity broker. If any representative of Bull Investor recommends a broker, it is done without any form of compensation, payment, or benefit from that broker or any related intermediary. Bull Investor receives no financial consideration from any stock or commodity broker recommended to a client.
            </p>
            <p>
                In adherence to the SEBI (Research Analysts) Regulations, 2014, Bull Investor has determined that neither the research analyst nor any of its representatives will engage in trading activities involving the securities or instruments discussed in our research services. We maintain no affiliations with any issuers of products or securities, thereby minimizing any actual or potential conflicts of interest. This policy safeguards the objectivity and independence of our research efforts. While we strive to provide accurate market predictions, investing in stock and commodity markets inherently carries risks, and we do not guarantee returns or the accuracy of our analyses. Although performance data for various products is available, it should not be interpreted as a guarantee of future results. Clients are encouraged to view our research reports as opinions and to make independent investment decisions.
            </p>
            <p>
                If clients request guidance on specific positions they have already taken, we will provide our  recommendations; however, these should be considered as opinions rather than definitive consultancy for final decision-making. We cannot be held accountable for any losses incurred by clients acting on this advice. It is important for clients to ensure that our research recommendations align with their individual risk profiles and capacity for risk. All research calls we provide are merely opinions, and it is up to the client to determine their actual trading actions.
            </p>
            <p>
                Furthermore, we do not have any connections with intermediaries, nor do we endorse any particular intermediaries’ services. Since our establishment, we have not faced any legal actions against us. All research services communicated by us, whether in writing or verbally by our representatives, will be recognized as official research services of our organization.
            </p>
            <p>
                Disclaimer - Bull Investor and its affiliates, including promoters, employees, directors, and stakeholders, may have held or may hold positions in the stocks or instruments featured in our research reports and communications
            </p>
        </div>
    )
}

export default StandardDisclaimer