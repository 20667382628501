
import React, {useState} from 'react';
import { CarFilled, CheckCircleFilled, LeftOutlined, RightOutlined, RocketFilled, TruckFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import classes from '../styles.module.css'
import Constants from '../allPackages.json'

const Packages = () => {
    const initialZIndexes = {
        standard: 1,
        premium: 2,
        combo: 0
    }
    const [activeCard, setActiveCard] = useState(1)
    const [zIndexes, setZIndexes] = useState(initialZIndexes)

    const zIndexCalculation = (activeCard) => {
        if(activeCard === 1){
           return setZIndexes(initialZIndexes) 
        }
        return setZIndexes({
            premium: 1,
            standard: activeCard === 0 ? 2 : 0,
            combo:  activeCard === 0 ? 0 : 2,
        })
    }

    const handleLeftNavigation = () => {
        if(activeCard === 0){
            return
        }
        zIndexCalculation(activeCard - 1)
        setActiveCard(activeCard - 1)
    }

    const handleRightNavigation = () => {
        if(activeCard === 2){
            return
        }
        zIndexCalculation(activeCard + 1)
        setActiveCard(activeCard + 1)
    }

    return (
        <>
            <Swiper 
                pagination={{
                    type: 'fraction',
                }} 
                navigation={true} 
                modules={[Navigation]} 
                className={classes.swiper}
            >
                <SwiperSlide>
                    <div className={`${classes.packageContainerSmall} ${classes.packageContainerStandard}`} style={{zIndex: zIndexes.standard}}>
                        <div className={classes.cardHeader}>
                            <CarFilled style={{ fontSize: '40px', color: '#CA5613' }}/>
                            <h3 className={`${classes.packageHeaderText} ${classes.standardBackground}`}>Standard</h3>
                        </div>
                        <div className={classes.packageBody}>
                            <div className={classes.costs}>
                                ₹{Constants.StandardCost}
                            </div>
                            {Constants.Standard.map(valuables => (
                                <div className={classes.packageTextContainer}>
                                    <CheckCircleFilled style={{ fontSize: '16px' }}/>
                                    <span className={classes.packageText}>{valuables}</span>
                                </div>
                            ))}
                        </div>
                        <Button type="primary" size="large" className={`${classes.submitBtn} ${classes.standardBackground}`}>Purchase Now</Button>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`${classes.packageContainerSmall} ${classes.packageContainerPremium}`} style={{zIndex: zIndexes.premium}}>
                        <div className={classes.cardHeader}>
                            <TruckFilled style={{ fontSize: '40px', color: '#61D21B' }}/>
                            <h3 className={`${classes.packageHeaderText} ${classes.premiumBackground}`}>Premium</h3>
                        </div>
                        <div className={classes.packageBody}>
                            <div className={classes.costs}>
                                ₹{Constants.PremiumCost}
                            </div>
                            {Constants.Premium.map(valuables => (
                                <div className={classes.packageTextContainer}>
                                    <CheckCircleFilled style={{ fontSize: '16px' }}/>
                                    <span className={classes.packageText}>{valuables}</span>
                                </div>
                            ))}
                        </div>
                        <Button type="primary" size="large" className={`${classes.submitBtn} ${classes.premiumBackground}`}>Purchase Now</Button>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`${classes.packageContainerSmall} ${classes.packageContainerCombo}`} style={{zIndex: zIndexes.combo}}>
                        <div className={classes.cardHeader}>
                            <RocketFilled style={{ fontSize: '40px', color: '#09C3E3' }}/>
                            <h3 className={`${classes.packageHeaderText} ${classes.comboBackground}`}>Combo</h3>
                        </div>
                        <div className={classes.packageBody}>
                            <div className={classes.costs}>
                                ₹{Constants.ComboCost}
                            </div>
                            {Constants.Combo.map(valuables => (
                                <div className={classes.packageTextContainer}>
                                    <CheckCircleFilled style={{ fontSize: '16px' }}/>
                                    <span className={classes.packageText}>{valuables}</span>
                                </div>
                            ))}
                        </div>
                        <Button type="primary" size="large" className={`${classes.submitBtn} ${classes.comboBackground}`}>Purchase Now</Button>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className={classes.packagePageContainer} >
                <LeftOutlined 
                    onClick={handleLeftNavigation} 
                    style={{ fontSize: '40px',  color: activeCard === 0 ? 'lightgray' : 'black'  }} 
                    className={`${classes.swipeBtn} ${classes.swipeLeftBtn}`} 
                    disabled={activeCard === 0} 
                />
                <div className={classes.allPackagesContainer}>
                    <div className={`${classes.packageContainer} ${classes.packageContainerStandard} ${classes.packageContainerStandardPosition}  ${activeCard === 0 ? classes.activeCard: classes.nonActiveCard}`} style={{zIndex: zIndexes.standard}}>
                        <div className={classes.cardHeader}>
                            <CarFilled style={{ fontSize: '40px', color: '#CA5613' }}/>
                            <h3 className={`${classes.packageHeaderText} ${classes.standardBackground}`}>Standard</h3>
                        </div>
                        <div className={classes.packageBody}>
                            <div className={classes.costs}>
                                ₹{Constants.StandardCost}
                            </div>
                            {Constants.Standard.map(valuables => (
                                <div className={classes.packageTextContainer}>
                                    <CheckCircleFilled style={{ fontSize: '16px' }}/>
                                    <span className={classes.packageText}>{valuables}</span>
                                </div>
                            ))}
                        </div>
                        <Button type="primary" size="large" className={`${classes.submitBtn} ${classes.standardBackground}`}>Purchase Now</Button>
                    </div>
                    <div className={`${classes.packageContainer} ${classes.packageContainerPremium} ${classes.packageContainerPremiumPosition} ${activeCard === 1 ? classes.activeCard: classes.nonActiveCard}`} style={{zIndex: zIndexes.premium}}>
                        <div className={classes.cardHeader}>
                            <TruckFilled style={{ fontSize: '40px', color: '#61D21B' }}/>
                            <h3 className={`${classes.packageHeaderText} ${classes.premiumBackground}`}>Premium</h3>
                        </div>
                        <div className={classes.packageBody}>
                            <div className={classes.costs}>
                                ₹{Constants.PremiumCost}
                            </div>
                            {Constants.Premium.map(valuables => (
                                <div className={classes.packageTextContainer}>
                                    <CheckCircleFilled style={{ fontSize: '16px' }}/>
                                    <span className={classes.packageText}>{valuables}</span>
                                </div>
                            ))}
                        </div>
                        <Button type="primary" size="large" className={`${classes.submitBtn} ${classes.premiumBackground}`}>Purchase Now</Button>
                    </div>
                    <div className={`${classes.packageContainer} ${classes.packageContainerCombo} ${classes.packageContainerComboPosition} ${activeCard === 2 ? classes.activeCard: classes.nonActiveCard}`} style={{zIndex: zIndexes.combo}}>
                        <div className={classes.cardHeader}>
                            <RocketFilled style={{ fontSize: '40px', color: '#09C3E3' }}/>
                            <h3 className={`${classes.packageHeaderText} ${classes.comboBackground}`}>Combo</h3>
                        </div>
                        <div className={classes.packageBody}>
                            <div className={classes.costs}>
                                ₹{Constants.ComboCost}
                            </div>
                            {Constants.Combo.map(valuables => (
                                <div className={classes.packageTextContainer}>
                                    <CheckCircleFilled style={{ fontSize: '16px' }}/>
                                    <span className={classes.packageText}>{valuables}</span>
                                </div>
                            ))}
                        </div>
                        <Button type="primary" size="large" className={`${classes.submitBtn} ${classes.comboBackground}`}>Purchase Now</Button>
                    </div>
                </div>
                <RightOutlined 
                    onClick={handleRightNavigation} 
                    style={{ fontSize: '40px', color: activeCard === 2 ? 'lightgray' : 'black' }} 
                    className={`${classes.swipeBtn} ${classes.swipeRightBtn}`} 
                    disabled={activeCard === 2} 
                />
            </div>
        </>
    )
}

export default Packages