import { WhatsAppOutlined } from '@ant-design/icons'
import { Outlet, useNavigate } from 'react-router-dom'

import { Whatsapp_URL } from '../constants'
import classes from '../styles.module.css'

const Layout = () => {

    const navigate = useNavigate()
    
    const handleClick = (route) => {
        navigate(`/${route}`)
    }

    const handleWhatsappClick = () => {
        window.open(Whatsapp_URL, "_blank", "rel=noopener noreferrer")
    }

    return (
        <div>
            <div className={classes.sideBar}>
                <div className={`${classes.SideBarContent} ${classes.userConsentSideBar}`} onClick={() => handleClick('user-consent')}>
                    User Consent
                </div>
                <div className={`${classes.SideBarContent} ${classes.legalDisclaimerSideBar}`} onClick={() => handleClick('legal-disclaimer')}>
                    Legal Disclaimer
                </div>   
            </div>
            <div className={classes.whatsappContainer} onClick={handleWhatsappClick}>
                <div className={classes.whatsappWrapper}>
                    <WhatsAppOutlined style={{fontSize: '32px', color: 'white'}} />
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default Layout