import classes from '../styles.module.css'

const PrivacyPolicy = () => {
    return (
        <div className={classes.footerLinksContainer}>
            <p>
                At Bull investor, we are committed to protecting the privacy of our customers personal data. We only collect the necessary personally identifiable information in order to respond to your requests for our services.This Privacy Policy outlines how we collect, use, and protect the information provided to us by you who register for trading purposes.
            </p>
            <p>
                This website gathers personally identifiable information about you solely when you choose to provide it knowingly and intentionally.this privacy policy applies to the current client as well as the former clients. Below are the detailed components of our privacy policy, outlined word by word.
            </p>
            <p>
                "If necessary under applicable laws and regulations, we may reveal the client's identity to the issuer of securities associated with the assets, or to the issuer's agent upon their request, as well as to any government or regulatory authority, without needing additional consent from the client."
            </p>
            <p>
                "When you register with us for trading, we collect specific personal details necessary provided by you to create and manage your trading exposure .This information is strictly used to facilitate and enhance your trading experience."
            </p>
            <p>
                "Your personal information is treated with the utmost confidentiality. We do not sell, transfer, or share your data with any third parties for their independent use or benefit. Your data will only be accessible to trusted partners and employees within Bull investor who require access to support your trading activity and fulfill legal or regulatory obligations."
            </p>
            <p>
                "By providing your contact information, you agree that we may use it to contact you through SMS, calls, emails, or other communication channels and Even If the contact  number you have entered is on DND. At any time you wish to opt out of such  communications, you can update your preferences by contacting us directly."
            </p>
            <p>
                "All information regarding Payment Gateway will be safe and secured for our site."
            </p>
            <p>
                "If you change your identity or any details associated with your trading process please notify us immediately to ensure your information remains current and accurate. This helps us to continue providing you with secure and uninterrupted service."
            </p>
            <p>
                "Alongside the services offered to you, your information (such as your mobile number and email address) may be utilized to send you newsletters, surveys, contest details, or updates regarding new services from the company. By subscribing to our services, you agree to our Privacy Policy and Terms of Use."
            </p>
            <p>
                "We reserve the right to update this Privacy Policy at any time to reflect changes in our practices or legal requirements. Any significant changes will be communicated through our website . Bull investor always advise to frequently check this page for any changes in Privacy Policy."
            </p>
            <p>
                By enrolling in our services, you accept our Privacy Policy and Terms of Use.
            </p>
        </div>
    )
}

export default PrivacyPolicy