import EighthIcon from "../Images/GoldenRules/EighthIcon"
import FifthIcon from "../Images/GoldenRules/FifthIcon"
import FirstIcon from "../Images/GoldenRules/FirstIcon"
import FourthIcon from "../Images/GoldenRules/FourthIcon"
import NinethIcon from "../Images/GoldenRules/NinethIcon"
import SecondIcon from "../Images/GoldenRules/SecondIcon"
import SeventhIcon from "../Images/GoldenRules/SeventhIcon"
import SixthIcon from "../Images/GoldenRules/SixthIcon"
import ThirdIcon from "../Images/GoldenRules/ThirdIcon"

export const Golden_Rules = [
    {
        cover: FirstIcon,
        title: 'Choose the right stocks',
    },
    {
        cover: SecondIcon,
        title: 'Take calculated risks',
    },
    {
        cover: ThirdIcon,
        title: 'Do thorough research',
    },
    {
        cover: FourthIcon,
        title: 'Take expert\'s help',
    },
    {
        cover: FifthIcon,
        title: 'Never be emotional',
    },
    {
        cover: SixthIcon,
        title: 'Redressal of grievance',
    },
    {
        cover: SeventhIcon,
        title: 'Use stop loss',
    },
    {
        cover: EighthIcon,
        title: 'Don\'t be greedy',
    },
    {
        cover: NinethIcon,
        title: 'Never take decision based on rumors',
    },
]