import React from 'react'
import { ClockCircleOutlined, HomeOutlined, MailOutlined, PhoneOutlined} from '@ant-design/icons';    

import classes from '../styles.module.css'

const ContactUs = () => {
    return (
        <div className={classes.contactUsContainer}>
            <h1 className={classes.contactUsHeader}>Contact Us</h1>
            <div className={classes.footerSectionContainer}>
                <div className={classes.footerSectionHeader}>
                    <ClockCircleOutlined style={{fontSize: '14px'}} /> 
                    <span><strong>Office Hours</strong></span>
                </div>
                <div className={classes.footerSectionBody}>
                    Mon - Sat 9:00 AM to 6:00 PM <br />
                    Sun - closed 
                </div>
            </div>
            <div className={classes.footerSectionContainer}>
                <div className={classes.footerSectionHeader}>
                    <MailOutlined style={{fontSize: '14px'}} /> 
                    <span><strong>Mail</strong></span>
                </div>
                <div className={classes.footerSectionBody}>
                    <a href="mailto:compliance@bullinvestor.com">compliance@bullinvestor.com</a>
                    <br />
                    <a href="mailto:sales@bullinvestor.com">sales@bullinvestor.com</a>
                </div>
            </div>
            <div className={classes.footerSectionContainer}>
                <div className={classes.footerSectionHeader}>
                    <PhoneOutlined style={{fontSize: '14px'}} /> 
                    <span><strong>Customer Support</strong></span>
                </div>
                <div className={classes.footerSectionBody}>
                    <a href="tel:0123456789">0123456789</a>
                </div>
            </div>
            <div className={classes.footerSectionContainer}>
                <div className={classes.footerSectionHeader}>
                    <HomeOutlined style={{fontSize: '14px'}} /> 
                    <span><strong>Address</strong></span>
                </div>
                <div className={classes.footerSectionBody}>
                    TTC Industrial Area, koper kharine , Navi Mumbai, Maharashtra-400705.
                </div>
            </div>
            
        </div>
    )
}

export default ContactUs