import classes from '../styles.module.css'

const GrievanceRedressalProcess = () => {
    return (
        <div className={classes.footerLinksContainer}>
            <p>
                At Bull Investor, our clients are at the heart of everything we do. We believe that effective communication and prompt resolution of concerns are essential to building trust and maintaining a positive relationship. Our Grievance Redressal Process is designed to provide you with a clear and transparent way to address any issues that may arise. We try to support our customers at every level and will go above and beyond to ensure that your grievances are resolved quickly, efficiently, and to your satisfaction. Your confidence and satisfaction are our top priorities, and we are here to assist you at any level.Grievance redressal is supported by a review mechanism, to minimize the recurrence of similar issues in future.
            </p>

<p>
The Grievance Redressal policy follows the following principles:
</p>

<p>
    1. Treating each grievance impartially and objectively, maintaining fairness in all decisions and outcomes.
</p>
<p>
    2. Aiming for prompt acknowledgment, swift investigation, and timely resolution of all grievances.
</p>
<p>
    3. Prioritizing client satisfaction and continuously improving our processes to meet client needs.
</p>
<p>
    4. Investors will be treated fairly at all times
</p>
<p>
we prioritize our customers'needs and provide comprehensive support throughout the grievance redressal process. We understand that issues may arise, and we encourage our clients to raise their complaints directly at our official email address - compliance@bullinvestor.com. Our dedicated team will thoroughly review each concern and work diligently to resolve any issues effectively as possible. Your satisfaction is our top priority, and we are here to assist you every step of the way.
</p>
<p>
Grievance Redressal Mechanism
</p>
<p>
we recognize that misunderstandings and lack of communication can often lead to client grievances. Common reasons for complaints may include confusion regarding trading processes or  miscommunication, lack of explanation, clarifications, understanding. we believe that open communication is key to resolving these misunderstandings. Therefore, if you find yourself facing any difficulties or have questions regarding our services, please feel free to connect with us. Our dedicated support team is ready to assist you in navigating any concerns and ensuring a positive experience with Bull Investor.
</p>
1. Clients seeking clarification or assistance regarding our services are encouraged to reach out to us for support. Whether you have questions about trading processes, or any other concerns, our dedicated team is available to help you. You can contact us via phone call or send an email to our official Email address -  compliance@bullinvestor.com
<p>
2. Clients can call on our Compliance officer number – +91-(compliance number)or alternatively, the Investor may write to the Research Analyst team at compliance@bullinvestor.com and the client can expect a reply within 10 business days of approaching the Research analyst team.
</p>
<p>
3. If you find that our response does not meet your expectations, you have the option to escalate your grievance to the Securities and Exchange Board of India (SEBI). You can lodge your complaint by visiting their website at http://scores.gov.in, or you may choose to write directly to any SEBI office. Additionally, grievances can be submitted via the SCORES mobile application, which can be downloaded using the link provided below link  :- 
https://play.google.com/store/apps/details?id=com.sebi
</p>
<p>
If customers wish to lodge a complaint, they can do so by contacting the Online Dispute Resolution (ODR) portal.ODR portal can be accessed via the following link https:/smartodr.in/
</p>
<p>
"Please feel free to reach out to us anytime"
</p>
        </div>
    )
}

export default GrievanceRedressalProcess