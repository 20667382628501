import classes from '../styles.module.css'

const RegisteredResearchAnalyst = () => {
    return (
        <div className={classes.footerLinksContainer}>
            <p>
                <strong>Brand</strong> - Bull Investor Research Analyst <br /><br />
                <strong>Compliance Officer name</strong> - Miss. Preeti Tripathi <br /><br />
                <strong>Contact details</strong> -(compliance phone number) <br /><br />
                <strong>Helpline number</strong> - (customer care number) <br /><br />
                <strong>Validity</strong> - Jun 12 2024 - Perpetual <br /><br />
                <strong>Compliance e-mail id</strong> - compliance@bullinvestor.com <br /><br />
                <strong>Sales e-mail id</strong>- sales@bullinvestor.com <br /><br />
                <strong>Office address</strong> - TTC Industrial Area, koper kharine , Navi Mumbai, Maharashtra 400705 <br /><br />
                <strong>Disclaimer </strong> - "Participating in the stock market can yield substantial rewards, but it also poses the risk of capital loss, with no guaranteed returns on investments." <br /><br />
                <strong>Standard warning</strong> - "Investment in securities market are subject to Market risks. Read all the related documents before investing.<br /><br />
            </p>
        </div>
    )
}

export default RegisteredResearchAnalyst