import classes from '../styles.module.css'

const RefundPolicy = () => {
    return (
        <div className={classes.footerLinksContainer}>
            <p>
                As you are aware, the stock market is uncertain and volatile in nature. To ensure we can help providing you with the quality service, we have a non-refundable fee associated with our subscription plans.  However our clients need to understand that we do not offer a 100% guarantee on our call so hence cannot offer any refund on subscriptions. all fees paid for the subscription, including to monthly, quarterly, and annual plans, are non-refundable and Once a subscription payment is made, it cannot be refunded under any circumstances.This ensures we can continuously offer quality insights, tools, and timely updates that you rely on until the end of you're current subscription plan
            </p>
            <p>
                "Read all the policies carefully before signing any form or paying the subscription to avoid any further consequences." 
            </p>
            <p>
                By subscribing, you agree to this non-refundable policy. Please contact our support team or either compliance department if you have any questions regarding our policy.
            </p>
        </div>
    )
}

export default RefundPolicy