import classes from '../styles.module.css'

const AdvertisingDisclaimer = () => {
    return (
        <div className={classes.footerLinksContainer}>
            <p>
                Please note: Trading in the stock market involves significant risk, and users are solely responsible for any results, including potential capital loss, arising from their trading decisions. SEBI-registered intermediaries or experts may share their analyses, research reports, and recommendations using the services of this company, which may include communication via SMS or email. The company or its employees may also disseminate this information on behalf of these intermediaries, but such communication should not be interpreted as an offer to buy or sell securities or as trading advice. All recommendations shared by the company, its employees, or its owners are solely for informational purposes and should not be the sole basis for making trading decisions. Users should not rely exclusively on information from this website for trading decisions. The company does not serve as a qualified financial advisor, and no information provided should be considered personalized investment advice; all content is intended for informational purposes only.
            </p>
            <p>
                1. At Bull Investor, we do not endorse any advertisements or recommendations through SMS, Telegram, or other messaging platforms. We encourage users to verify any information independently before making any commitments. Our services are only promoted through social media paid ads that adhere to all platform guidelines and terms. We aim to provide clear, relevant information without unnecessary technical or legal jargon to ensure a straightforward experience for our investors. 
            </p>
            <p>
                2. We provide alerts regarding market opportunities; however, due to the volatile nature of the stock market, we cannot guarantee outcomes or profits from the trades we suggest. Our alerts are for informational purposes only, and users are solely responsible for making buy or sell decisions based on their own judgment.
            </p>
            <p>
                3. Bull Investor does not request advance payments nor ask users to transfer funds through any social media advertisements.
            </p>
            <p>
                4. We do not provide any assurances or guarantees of profit/ return on the recommendations given through our alerts.
            </p>
            <p>
                5. All payments must be made to the company's designated current account. Bull Investor does not request payments to any individual bank account.
            </p>
            <p>
                6. Users are advised to evaluate their financial situation carefully before making any investment decisions, as we do not claim to deliver 100% accuracy in our alerts.
            </p>
            <p>
                7. Information regarding our SEBI registration, including the name of our Registered Analyst (RA), registered office address, SEBI Registration Number, logo, brand name, trade name, and CIN, can be found on our website. We encourage all investors to use this information as a resource and reference only.
            </p>
            <p>
                8. Our company offers a one-time free live demo. However, this demo is not indicative of lifetime free services. Additionally, recommendations provided in the demo are not intended as final advice to buy or sell securities.
            </p>
            <p>
                9. Our company holds no financial liability to clients who choose to use either our paid or free services. The investments discussed or recommended in our analyses and reports may not be suitable for all investors. Each investor should independently assess their financial position and objectives, consulting advisors as needed, before making any decisions. While we strive to provide reliable information, we cannot guarantee its accuracy or completeness, as analyses reflect the personal views of contributors. We encourage users to view our website content purely for informational purposes and to use their own judgment when making investment choices.
            </p>
            <p>
                10. For further details on our services and company terms and conditions, please visit our official website: www.Bullinvestor.co.in.
            </p>
        </div>
    )
}

export default AdvertisingDisclaimer