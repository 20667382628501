import SignatureCanvas from 'react-signature-canvas'
import { Button, Modal, notification } from 'antd'
import { useEffect, useRef, useState } from 'react'

import classes from '../styles.module.css'
import './sigCanvas.css'

const DigitalSignature = ({handleClose, handleSubmit}) => {
    const sigCanvas = useRef({})
    const [api, contextHolder] = notification.useNotification();
    const [canvasWidth, setCanvasWidth] = useState(0)
    const [canvasHeight, setCanvasHeight] = useState(0)

    useEffect(() => {
    if (sigCanvas.current) {
      // Set the canvas width after the modal is rendered
      const parentWidth = document.getElementById('digital-signature-body')?.clientWidth
      const parentHeight = document.getElementById('digital-signature-body')?.clientHeight
      // get the parent width/height and minus the padding
      setCanvasWidth(( parentWidth - 48) || 300);
      setCanvasHeight((parentHeight - 48) || 300)
    }
  }, []);

    const handleSave = () => {
        if(sigCanvas.current.isEmpty()) {
             return api.error({
                message: 'Error',
                description:
                    'Digital Signature is mandatory.',
            });
        }
        const base64String = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        handleSubmit(base64String)
    }

    const handleClear = () => {
        sigCanvas.current.clear()
    }

    return (
      <>
        {contextHolder}
        <Modal 
            style={{height: '800px'}}
            styles={{
                body: { backgroundColor: 'lightgrey', display: 'flex', alignItems: 'center', justifyContent: 'center' }, // turns the "Modal's body" to red
            }}
            title="Digital Signature" 
            width={800}
            open 
            footer={
            <>
                <Button type="primary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button type="primary" onClick={handleClear}>
                    Clear
                </Button>
                <Button type="primary" onClick={handleSave}>
                    Submit
                </Button>
            </>
            }
            onCancel={handleClose}
        >
            <div className={classes.signatureContainer} id="digital-signature-body">
                <SignatureCanvas 
                    ref={sigCanvas} 
                    penColor='black' 
                    canvasProps={{className: 'sigCanvas', width: canvasWidth, height: canvasHeight}}
                    backgroundColor='white'
                />
            </div>
        </Modal> 
      </>   
    )
}

export default DigitalSignature