import React from 'react'

import classes from '../styles.module.css'

const Disclaimer = () => {
    return (
            <div className={classes.disclaimerComponentContainer}>
                <ul>
                    <li>Investment in the stock market is subject to <strong>market risk</strong>, we DO NOT offer any guaranteed profit services or Fixed Returns Services. We DO NOT provide any assurance or guarantee of profit or returns with any of our services. Trading in the Stock Market may result in Partial or Complete loss of Gains as well as Initial Capital. Before taking our Research Alerts Services & any services of Bull Investors firm, Clients should read carefully the Disclaimer, Legal disclaimer, terms and conditions, refund policy and other policies of our company. We do not provide any recommendation or any services through Telegram/ Instagram/Etc . We DO NOT accept payment of Research Alerts Service fee in any personal or Individual bank account, all <strong>payments</strong> made should be done in <strong>Bull Investor current account</strong> only..</li>
                    <li>Investing and Trading in stock market is <strong>risky</strong>. It Involves both profit and loss, Due to leverage both profit and loss are exaggerated, our research service gives research alerts for trading ideas in which both target and stop loss is mentioned, however execution of trade is solely the responsibility of the client. We DO NOT provide any trade execution services. All our research alerts are to be considered only as a reference tool and clients should not consider our research alerts as Personal Investment/Trading Research in any condition. We <strong>DO NOT</strong> take any <strong>responsibility for any losses</strong> that the User may incur.</li>
                </ul>
            </div>
    )
}

export default Disclaimer