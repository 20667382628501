import React from 'react'
import { Card } from 'antd'

import classes from '../styles.module.css';
import { Golden_Rules } from './constants';

const GoldenRules = () => {
    const {Meta} = Card
    return (
        <div className={classes.goldenRulesContainer}>
            <h3 className={classes.goldenRulesHeader}>9 Golden Rules for Trading in Stock Market</h3>
            <div className={classes.goldenRulesBody}>
                {Golden_Rules.map((rule) => (
                    <Card
                        className={classes.card}
                        key={rule.title}
                        style={{ width: 360, backgroundColor: 'lightgrey', padding: '16px 16px 0'}}
                        cover={<rule.cover />}
                    >
                        <Meta title={<span style={{ whiteSpace: 'pre-wrap' }}>{rule.title}</span>} style={{textAlign: 'center'}} />
                    </Card>
                ))}
            </div>
        </div>
    )

}

export default GoldenRules