import { useEffect, useState } from 'react';
import { notification } from 'antd';
import {
    doc,
    updateDoc
} from "firebase/firestore";
import { useSearchParams } from 'react-router-dom';

import { db } from "../firebase.config.js";
import classes from '../styles.module.css';

const MailVerification = () => {

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id")
    const [api, contextHolder] = notification.useNotification();
    const [isMailVerified, setIsMailVerified] = useState(false)
   
    useEffect(() => {
         const updateUser = async () => {
            try{
                const userDoc = doc(db, "users", id);
                await updateDoc(userDoc, {emailVerified: true});
                console.log("updated mail verification")
                setIsMailVerified(true)
            } catch(err) {
                api.error({
                    message: 'Error',
                    description: 'Error in verifying the mail address.',
                });
            }
      
    };
        if(id) {
            updateUser()
        }
    }, [id, api])

    return (
        <>
            {contextHolder}
            <div className={classes.mailVerified}>
                {isMailVerified ? `Mail Successfully verified.` : `Mail is getting verified. Please wait for a sec.`}
            </div>
        </>
    )
}

export default MailVerification

