import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import AdvertisingDisclaimer from './AdvertisingDisclaimer';
import BankDetails from './BankDetails';
import ContactUs from './ContactUs';
import Footer from './Footer';
import Header from './Header';
import HomePage from './HomePage';
import LegalDisclaimer from './LegalDisclaimer';
import Packages from './Packages';
import PrivacyPolicy from './PrivacyPolicy';
import RefundPolicy from './RefundPolicy';
import StandardDisclaimer from './StandardDisclaimer';
import UserConsent from './UserConsent';
import GrievanceRedressalProcess from './GrievanceRedressalProcess';
import RegisteredResearchAnalyst from './RegisteredResearchAnalyst';

import 'swiper/css';
import 'swiper/css/navigation';

function App() {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [location.pathname])

  return (
        <>
            <Header />
            <div style={{flex: 1, 
                    backgroundImage: 'linear-gradient(skyblue, white)',
                    backgroundSize: '100% 90%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top'
            }}>
                <Routes>
                    <Route exact path='/' element={<HomePage/>} />
                    <Route path='/packages' element={<Packages/>} />
                    <Route path='/bank-details' element={<BankDetails/>} />
                    <Route path='/contact-us' element={<ContactUs/>} />
                    <Route path='/refund-policy' element={<RefundPolicy/>} />
                    <Route path='/legal-disclaimer' element={<LegalDisclaimer/>} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                    <Route path='/user-consent' element={<UserConsent/>} />
                    <Route path='/advertising-disclaimer' element={<AdvertisingDisclaimer/>} />
                    <Route path='/standard-disclaimer' element={<StandardDisclaimer/>} />
                    <Route path='/grievance-redressal-process' element={<GrievanceRedressalProcess/>} />
                    <Route path='/registered-research-analyst' element={<RegisteredResearchAnalyst/>} />
                </Routes>
            </div>
            <Footer />
        </>
  );
}

export default App;
