import classes from '../styles.module.css'

const LegalDisclaimer = () => {
    return (
        <div className={classes.footerLinksContainer}>
            <p>
                All reports, alerts, and other materials published by Bull Investor are the intellectual property of Bull Investor and are protected by copyright and other intellectual property . Any reproduction, copying, modification, or redistribution of these materials, in whole or in part, without prior written consent from Bull Investor, is strictly prohibited.Violation of this policy may result in legal action. Bull Investor reserves the right to modify, update, or revoke access to these materials at its sole discretion.
            </p>
            <p>
                Bull Investor is an independent equity research entity operating under its proprietary license and does not act as an investment advisor. The information provided is solely for reference purposes, intended to help users make informed choices but should be utilized at their own discretion and risk. This content does not constitute a solicitation to buy or an offer to sell any securities, and Bull Investor, along with its promoters, employees, and associates, cannot be held responsible for any losses, investments, or decisions arising from reliance on this information. The information provided does not consider individual investment goals, financial conditions, or personal needs, and users should carefully evaluate any recommendations in light of their circumstances, consulting a licensed investment advisor if needed. This content is specifically designed for Indian audiences and is not intended for access in jurisdictions where it may be restricted, such as the USA, Canada, or European Union. All information is offered "as is," and although Bull Investor aims to ensure accuracy, it does not warrant its completeness or reliability and disclaims any implied or express warranties. Bull Investor and its affiliates may hold shares in the companies discussed within the reports. By accessing this information, users agree to the Terms and Conditions, understanding that past performance does not guarantee future results. Users further agree to indemnify Bull Investor, its promoters, employees, and stakeholders from any legal action. Any research provided by Bull Investor is for informational purposes only, with the user responsible for any decisions to buy, sell, or hold securities. All risks, including any gains or losses, lie solely with the user, and Bull Investor and its affiliates are not liable for any compensation or reimbursement related to such outcomes.
            </p>
        </div>
    )
}

export default LegalDisclaimer