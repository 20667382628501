import { useState } from 'react';
import { Button, Image, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import Disclaimer from '../Disclaimer';
import TradingImg from "../Images/1705072458804.png";
import classes from '../styles.module.css';
import GoldenRules from '../GoldenRules/index.jsx';
import FormContainer from '../FormContainer/index.jsx';
import Constants from "./InputFields.json"

const HomePage = ({history}) => {
    const initialData = {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
    }
    
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)

    const handleModalClose = () => {
        setShowDisclaimerModal(false)
    }

    return (
        <>
            {showDisclaimerModal ? 
                (<Modal 
                    title="DISCLAIMER"
                    open 
                    footer={
                    <Button type="primary" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    }
                    onCancel={handleModalClose}>
                        <Disclaimer /> 
                </Modal>)
            : null}
            <div className={classes.disclaimerContainer}>
                <div className={classes.disclaimerSectionContainer}>
                    <InfoCircleOutlined style={{fontSize: '13px', color: 'red'}}/>
                    <span className={classes.disclaimerText}>Investment in the stock market is subject to market risk, we DO NOT offer any guaranteed profit services or Fixed Returns Services.</span>
                    <Button type="link" size="small" onClick={() => setShowDisclaimerModal(true)}>Read More</Button>
                </div> 
            </div>
            <div className={classes.homePageContainer}>
                <Image
                    width={'60%'}
                    src={TradingImg}
                    className={classes.tradingImg}
                    preview={false}
                />
                <FormContainer 
                    inputFields={Constants.InputFields}
                    title="Register for live demo"
                    initialState={initialData}
                />
            </div>
            <GoldenRules />
        </>
    )
}

export default HomePage